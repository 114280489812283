import { EditorSDK, EditorReadyOptions } from '@wix/platform-editor-sdk';
import { HandleActionPayload } from '../../constants';
import { EditorScriptApi } from '../../api/api';
import { ServicePageMigrator } from './service-page-migrator';
import { CalendarPageMigrator } from './calendar-page-migrator';
import { FormPageMigrator } from './form-page-migrator';
import { EcomPagesMigrator } from './ecom-pages-migrator';
import { BasePageMigrator } from './base-page-migrator';
import { ServiceListMigrator } from './service-list-migrator';
import { Translations } from '../translations';
import { setBarStep } from '../progressbar-utils';
import {
  addBookingsPagesAsPanel,
  removeBookCheckoutPageOnEcom,
} from '../pages-panel-actions';
import { isADI } from '../editor-sdk-actions';

export const migrateToOOI = async ({
  editorSDK,
  appToken,
  instance,
  editorScriptApi,
  handleActionPayload,
  editorOptions,
  displayStepsBar,
}: {
  editorSDK: EditorSDK;
  appToken: string;
  instance: string;
  editorScriptApi: EditorScriptApi;
  handleActionPayload: HandleActionPayload;
  editorOptions: EditorReadyOptions;
  displayStepsBar?: {
    editorTranslation: Translations;
    currentStep: number;
  };
}) => {
  let currentStep = displayStepsBar?.currentStep || 0;
  const pageMigrators: BasePageMigrator[] = [
    new ServiceListMigrator(editorSDK, appToken, instance, handleActionPayload),
    new ServicePageMigrator(
      editorSDK,
      appToken,
      instance,
      handleActionPayload,
      editorScriptApi,
    ),
    new CalendarPageMigrator(
      editorSDK,
      appToken,
      instance,
      handleActionPayload,
    ),
    new FormPageMigrator(editorSDK, appToken, instance, handleActionPayload),
    new EcomPagesMigrator(
      editorSDK,
      appToken,
      instance,
      handleActionPayload,
      editorScriptApi,
    ),
  ];

  for (const pageMigrator of pageMigrators) {
    try {
      if (displayStepsBar) {
        await setBarStep(
          editorSDK,
          appToken,
          displayStepsBar.editorTranslation,
          pageMigrator.stepTranslate,
          currentStep,
        );
      }
      if (await pageMigrator.shouldMigrate()) {
        await pageMigrator.execute();
      }
      currentStep++;
    } catch (e) {
      const errorMessage = `${
        e?.message ? e.message : JSON.stringify(e)
      } - errorCode: OOI_MIGRATION, page: ${pageMigrator.widgetName}`;
      throw new Error(errorMessage);
    }
  }

  try {
    await addBookingsPagesAsPanel(editorSDK, appToken);

    if (!isADI(editorOptions)) {
      await removeBookCheckoutPageOnEcom(editorSDK, appToken, editorScriptApi);
    }
  } catch (e) {
    const errorMessage = `${
      e?.message ? e.message : JSON.stringify(e)
    } - errorCode: FINISH_MIGRATION`;
    throw new Error(errorMessage);
  }

  console.log('migration completed successfully');
};
