import { BOOKINGS_DEF_ID, PageId } from '../constants';
import { getBookingsDefId } from './editor-sdk-actions';
import { EditorScriptApi } from '../api/api';

export async function addBookingsPagesAsPanel(editorSDK, appToken) {
  try {
    const bookingsDefId = await getBookingsDefId(editorSDK);
    const bookingsTpaApplicationId = (
      await editorSDK.tpa.app.getDataByAppDefId(appToken, bookingsDefId)
    ).applicationId;
    const allSitePages = await editorSDK.pages.data.getAll();
    const bookingsPages = allSitePages.filter(
      (page) =>
        page.tpaApplicationId === bookingsTpaApplicationId &&
        page.tpaPageId !== PageId.BOOKINGS_MEMBERS_AREA,
    );

    return Promise.all(
      bookingsPages.map((bookingPage) =>
        editorSDK.pages.data.update(appToken, {
          pageRef: { id: bookingPage.id, type: bookingPage.type },
          data: { managingAppDefId: bookingsDefId },
        }),
      ),
    );
  } catch (e) {
    throw new Error(
      `${
        e?.message ? e.message : JSON.stringify(e)
      } - errorCode: ADD_BOOKINGS_PAGES_AS_PANEL`,
    );
  }
}

export async function createBookCheckoutState(
  editorSDK,
  appToken,
  allSitePages,
) {
  const bookCheckoutPageData = allSitePages.find(
    (page) => page.tpaPageId === PageId.BOOKINGS_CHECKOUT,
  );
  if (bookCheckoutPageData) {
    await editorSDK.document.pages.setState(appToken, {
      state: {
        bookCheckoutPage: [{ id: bookCheckoutPageData.id }],
      },
    });
  }
}

export async function createServicePageState(
  editorSDK,
  appToken,
  allSitePages,
) {
  const servicePageData = allSitePages.find(
    (page) => page.tpaPageId === PageId.BOOKINGS_SERVICE_PAGE,
  );
  if (servicePageData) {
    await editorSDK.document.pages.setState(appToken, {
      state: {
        servicePage: [{ id: servicePageData.id }],
      },
    });
  }
}

export async function createCalendarPageState(
  editorSDK,
  appToken,
  allSitePages,
) {
  const calendarPageData = allSitePages.find(
    (page) => page.tpaPageId === PageId.BOOKINGS_CALENDAR_PAGE,
  );
  if (calendarPageData) {
    await editorSDK.document.pages.setState(appToken, {
      state: {
        bookingCalendar: [{ id: calendarPageData.id }],
      },
    });
  }
}

export async function createBookingCalendarState(
  editorSDK,
  appToken,
  allSitePages,
) {
  const bookingCalendarData = allSitePages.find(
    (page) => page.tpaPageId === PageId.BOOKINGS_CALENDAR_PAGE,
  );
  if (bookingCalendarData) {
    await editorSDK.document.pages.setState(appToken, {
      state: {
        bookingCalendar: [{ id: bookingCalendarData.id }],
      },
    });
  }
}

export async function createBookingFormState(
  editorSDK,
  appToken,
  allSitePages,
) {
  const bookingFormData = allSitePages.find(
    (page) => page.tpaPageId === PageId.BOOKINGS_FORM_PAGE,
  );
  if (bookingFormData) {
    await editorSDK.document.pages.setState(appToken, {
      state: {
        bookingForm: [{ id: bookingFormData.id }],
      },
    });
  }
}

export async function removeBookCheckoutPageOnEcom(
  editorSDK,
  appToken,
  editorScriptApi: EditorScriptApi,
) {
  try {
    const allSitePages = await editorSDK.pages.data.getAll();
    const bookCheckoutPageData = allSitePages.find(
      (page) => page.tpaPageId === PageId.BOOKINGS_CHECKOUT,
    );
    if (bookCheckoutPageData) {
      const isEcomReady = await editorScriptApi.getIsBookingsOnEcom();
      if (isEcomReady) {
        await editorSDK.document.pages.remove(appToken, {
          pageRef: {
            id: bookCheckoutPageData.id,
          },
          shouldShowEditorRemovePanel: false,
        });
      }
    }
  } catch (e) {
    const errorMessage = `${
      e?.message ? e.message : JSON.stringify(e)
    } - errorCode: removeBookCheckoutPageOnEcom`;
    console.log(errorMessage);
  }
}
export async function handleOpenBookingsPagesPanel(editorSDK, appToken) {
  const { check, show } = editorSDK.editor.deeplink;
  const pagesPanel = {
    type: 'pagesPanel',
    params: [BOOKINGS_DEF_ID],
  };
  const editorDeepLinkToBookingsPagesPanel = await check(appToken, pagesPanel);
  editorDeepLinkToBookingsPagesPanel && (await show(appToken, pagesPanel));
}

export async function updatePagesTranslations(
  editorSDK,
  appToken,
  allSitePages,
  editorTranslation,
) {
  try {
    const bookingsDefId = await getBookingsDefId(editorSDK);
    const bookingsTpaApplicationId = (
      await editorSDK.tpa.app.getDataByAppDefId(appToken, bookingsDefId)
    ).applicationId;
    const bookingsPages = allSitePages.filter(
      (page) =>
        page.tpaApplicationId === bookingsTpaApplicationId &&
        page.tpaPageId !== PageId.BOOKINGS_MEMBERS_AREA,
    );
    for (const bookingPage of bookingsPages) {
      if (
        bookingPage.tpaPageId === PageId.BOOKINGS_LIST &&
        bookingPage.title !== 'Book Online'
      ) {
        continue;
      } else {
        await editorSDK.pages.data.update(appToken, {
          pageRef: { id: bookingPage.id, type: bookingPage.type },
          data: {
            title: editorTranslation.t(
              `bookings-pages.${bookingPage.tpaPageId}.title`,
            ),
          },
        });
      }
    }
  } catch (e) {
    const errorMessage = `${
      e?.message ? e.message : JSON.stringify(e)
    } - errorCode: updatePagesTranslations`;
    console.log(errorMessage);
  }
}
